<template>
	<button title="Download report"
			@click.prevent.stop="downloadExcel" :disabled="loading">
		<template v-if="loading">
			<slot name="loading">
				<i class="fas fa-circle-notch fa-spin"></i>
			</slot>
		</template>
		<template v-else>
			<slot>
				<i class="fa fa-file-excel"></i>
			</slot>
		</template>
	</button>
</template>

<script>
	import api from '@/api'

	export default {
		props: {
			report: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				loading: false
			}
		},
		methods: {
			async downloadExcel() {
				this.loading = true
				let _data = await api.Reports.downloadExcel(this.report.Id)

				var link = document.createElement('a');
				link.href = _data.excel;
				link.download = `report-${this.report.Id}-v-${this.report.Version}.xlsx`;
				link.click();
				this.loading = false
			}

		}
	}
</script>
