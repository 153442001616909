<template>
	<div>
		<router-link v-if="canWrite('spinners')" :to="{
			name: 'projects-editor-uuid-cuuid-tests-gameplay-new',
			params: {
				uuid: $route.params.uuid,
			   cuuid: $route.params.cuuid
			}
		}" class="btn btn-link my-2">
			<i class="fa fa-plus"></i>
			NEW GAMEPLAY REPORT
		</router-link>

		<div class="my-2">

			<table class="table table-hover">
				<thead class="font-weight-bold">
				<tr>
					<td>ID</td>
					<td>Date/time</td>
					<td>Version</td>
					<td>Status</td>
					<td>Players</td>
					<td>Bankroll</td>
					<td>Max hands</td>
					<td>Bet</td>
					<td>Lines</td>
					<td>RTP</td>
					<td>Feature triggered</td>
					<!--
					<td>Type</td>
					<td>Bet</td>
					<td>Lines</td>
					<td>Spins</td>
					<td>RTP</td>
					-->
					<td></td>
				</tr>
				</thead>
				<tbody>
				<router-link class="cursor-pointer" :to="{
					name: 'projects-editor-uuid-cuuid-tests-gameplay-report_id',
					params: {
						report_id: report.Id
					}
				}" tag="tr" v-for="report in reports" :key="report.Id">
					<td v-text="report.Id"></td>
					<td v-rel-date="report.CreatedAt"></td>
					<td v-text="report.Version"></td>
					<td>
						<spinner-status-label :status="report.Status"></spinner-status-label>
						<div class="mt-1 p-2 bg-light text-nowrap text-truncate" style="max-width: 200px"
							 v-text="report.Notes" :title="report.Notes" v-if="report.Notes"></div>
					</td>
					<td>
						<div>{{report.PlayersDone}} / {{report.Players}}</div>

						<div class="progress" style="height: 4px;">
							<div class="progress-bar"
								 :class="report.Status === 'running' ? 'progress-bar-animated progress-bar-striped': null"
								 :style="{width: `${Math.round(report.PlayersDone / report.Players * 100)}%`}"></div>
						</div>


					</td>
					<td>{{report.Bankroll}}</td>
					<td>{{report.MaxHands}}</td>
					<td>{{report.Bet}}</td>
					<td>{{report.Paylines}}</td>
					<td>{{report.TotalRtp | numeral('0.00%')}}</td>
					<td>{{report.AnyFeatureTriggeredPercentage | numeral('0.00%')}}</td>
					<!--
					<td>
						<spinner-status-label :status="report.Status"></spinner-status-label>
						<div class="mt-1 p-2 bg-light text-nowrap text-truncate" style="max-width: 200px"
							 v-text="report.Notes" :title="report.Notes" v-if="report.Notes"></div>
					</td>
					<td v-text="report.Type"></td>
					<td v-text="report.Bet"></td>
					<td>
						<div class="badge text-uppercase" v-for="payline in report.Paylines">{{payline}}</div>
					</td>
					<td>
						<div class="badge text-uppercase" v-for="spin in report.Spins"
							 v-if="report.Type === 'standard'">{{spin|numeral('Oa')}}
						</div>
						<div class="badge text-uppercase"
							 v-if="report.Type === 'stress'">{{report.Spins[0]|numeral('Oa')}} x
							{{report.Spins[1]|numeral('Oa')}} spins
						</div>
					</td>
					<td>
						<template v-if="report.Summary.balance.total_bet">
							{{report.Summary.balance.total_win / report.Summary.balance.total_bet | numeral('0.00%')}}
						</template>

						<div class="text-small">{{report.NbSpinnerTests - report.NbSpinnerTestsDone}} tests left</div>
					</td>
					<td>
						<div class="d-flex flex-nowrap">
							<report-excel-download-button class="btn btn-link btn-primary"
														  :report="report"></report-excel-download-button>
							<button :disabled="deleting" class="btn btn-link btn-danger"
									@click.prevent.stop="deleteReport(report.Id)" v-if="canWrite('spinners')">
								<i class="fa fa-trash"></i>
							</button>
						</div>
					</td>
					-->
					<td>
						<button v-if="canWrite('spinners')" :disabled="deleting" class="btn btn-link text-danger"
								@click.prevent.stop="deleteReport(report.Id)">
							<i
									class="fa fa-trash"></i></button>
					</td>
				</router-link>
				</tbody>
			</table>

		</div>

	</div>
</template>

<script>
	import api from '@/api'
	import SpinnerStatusLabel from '@/components/spinner/spinner-status-label'
	import ReportExcelDownloadButton from "../../../../../../../components/reports/report-excel-download-button";
	import messageBox from '@/components/modals/alert'

	export default {
		data() {
			return {
				reports: [],
				interval: null,
				deleting: false
			}
		},
		async mounted() {
			this.loadReports()
			this.interval = setInterval(this.loadReports, 10000)
		},
		destroyed() {
			clearInterval(this.interval)
		},
		methods: {
			async loadReports() {
				this.reports = await api.GameplayReports.getList({uuid: this.$route.params.cuuid})
			},
			async deleteReport(report_id) {
				this.deleting = true
				await api.GameplayReports.delete(report_id).finally(() => this.deleting = false)
				this.loadReports()
			},
			async deleteReport(report_id) {
				this.deleting = true
				await api.GameplayReports.delete(report_id).catch(() => this.deleting = false)
				await this.loadReports()
				this.deleting = false
				messageBox({
					message: `Report deleted!`
				})

			}
		},
		components: {
			ReportExcelDownloadButton,
			SpinnerStatusLabel
		}
	}
</script>
