<template>
	<div :class="_class" class="text-nowrap">
		<i :class="icon" class="mr-2"></i>
		<span v-text="status" class="text-capitalize"></span>
	</div>
</template>

<script>
	export default {
		props: {
			status: {
				type: String,
				default: ''
			}
		},
		computed: {
			icon() {
				let iconClass = ""
				switch (this.status) {
					case "ready":
						iconClass = "fa fa-circle fa-pulse"
						break;
					case "uploading":
						iconClass = "fa fa-upload fa-pulse"
						break;
					case "running":
						iconClass = "fa fa-sync fa-spin"
						break;
					case "created":
						break;
					case "done":
						iconClass = "fa fa-check-circle"
						break;
					case "error":
						iconClass = "fa fa-exclamation-circle"
						break;
				}

				return iconClass
			},
			_class() {
				let _class = "text-info"
				switch (this.status) {
					case "ready":
						_class = "text-primary"
						break;
					case "running":
						_class = "text-warning"
						break;
					case "created":
						break;
					case "done":
						_class = "text-success"
						break;
					case "error":
						_class = "text-danger"
						break;
				}

				return _class
			}
		}
	}
</script>
