var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.canWrite('spinners'))?_c('router-link',{staticClass:"btn btn-link my-2",attrs:{"to":{
		name: 'projects-editor-uuid-cuuid-tests-gameplay-new',
		params: {
			uuid: _vm.$route.params.uuid,
		   cuuid: _vm.$route.params.cuuid
		}
	}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" NEW GAMEPLAY REPORT ")]):_vm._e(),_c('div',{staticClass:"my-2"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.reports),function(report){return _c('router-link',{key:report.Id,staticClass:"cursor-pointer",attrs:{"to":{
				name: 'projects-editor-uuid-cuuid-tests-gameplay-report_id',
				params: {
					report_id: report.Id
				}
			},"tag":"tr"}},[_c('td',{domProps:{"textContent":_vm._s(report.Id)}}),_c('td',{directives:[{name:"rel-date",rawName:"v-rel-date",value:(report.CreatedAt),expression:"report.CreatedAt"}]}),_c('td',{domProps:{"textContent":_vm._s(report.Version)}}),_c('td',[_c('spinner-status-label',{attrs:{"status":report.Status}}),(report.Notes)?_c('div',{staticClass:"mt-1 p-2 bg-light text-nowrap text-truncate",staticStyle:{"max-width":"200px"},attrs:{"title":report.Notes},domProps:{"textContent":_vm._s(report.Notes)}}):_vm._e()],1),_c('td',[_c('div',[_vm._v(_vm._s(report.PlayersDone)+" / "+_vm._s(report.Players))]),_c('div',{staticClass:"progress",staticStyle:{"height":"4px"}},[_c('div',{staticClass:"progress-bar",class:report.Status === 'running' ? 'progress-bar-animated progress-bar-striped': null,style:({width: `${Math.round(report.PlayersDone / report.Players * 100)}%`})})])]),_c('td',[_vm._v(_vm._s(report.Bankroll))]),_c('td',[_vm._v(_vm._s(report.MaxHands))]),_c('td',[_vm._v(_vm._s(report.Bet))]),_c('td',[_vm._v(_vm._s(report.Paylines))]),_c('td',[_vm._v(_vm._s(_vm._f("numeral")(report.TotalRtp,'0.00%')))]),_c('td',[_vm._v(_vm._s(_vm._f("numeral")(report.AnyFeatureTriggeredPercentage,'0.00%')))]),_c('td',[(_vm.canWrite('spinners'))?_c('button',{staticClass:"btn btn-link text-danger",attrs:{"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteReport(report.Id)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()])])}),1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"font-weight-bold"},[_c('tr',[_c('td',[_vm._v("ID")]),_c('td',[_vm._v("Date/time")]),_c('td',[_vm._v("Version")]),_c('td',[_vm._v("Status")]),_c('td',[_vm._v("Players")]),_c('td',[_vm._v("Bankroll")]),_c('td',[_vm._v("Max hands")]),_c('td',[_vm._v("Bet")]),_c('td',[_vm._v("Lines")]),_c('td',[_vm._v("RTP")]),_c('td',[_vm._v("Feature triggered")]),_c('td')])])
}]

export { render, staticRenderFns }